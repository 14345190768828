import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import SnippetJS from "../../components/SnippetJS";
import FaqComponent from "../../components/mdxComponents/FaqComponent";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Hem: "/",
  "Analytics Consulting": "/se/analysrådgivning",
  "Data Layer": "/se/data-lager"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/data-layer"
);

const DataLayer = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Data Layer förklarad med Tutorial [2020]"
        description="Ett data-lager är det grundläggande konceptet för alla professionella webbanalysupplägg. Men vad är det egentligen och hur implementerar vi det?"
        lang="se"
        image="data-layer-code-hero-image3"
        alternateLangs={alternateLangs}
        canonical="/se/data-lager"
        datePublished="2024-06-13T04:32:43.188Z"
        dateModified="2024-06-13T15:21:48.881Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="data-layer/data-layer-code-hero-image3.png"
          alt="Visualisering av Google Tag Manager Data Layer i webbläsarens konsol"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Data Lager</H>
        <p>
          I samband med tagghantering och webbanalys har du kanske hört termen <b>data-lager</b>. Det är den grundläggande
          elementet i en ambitiös webbanalysuppsättning eftersom alla datapunkter och spårningsregler är beroende av det.
        </p>
        <p>
          Därför behandlas det som ett icke-förhandlingsbart krav för alla analysuppsättningar i den digitala analysvärlden.
          Dock finns det scenarier där det inte är nödvändigt.
        </p>
        <p>
          Därför vill jag förklara <Link to="/se/data-lager#vad-ar-ett-data-lager">vad ett data-lager är</Link>,{" "}
          dess <Link to="/se/data-lager#data-lager-fordelar">fördelar</Link>, och skillnaderna mellan{" "}
          <Link to="/se/data-lager#data-lager-for-google-tag-manager">data-lager för Google Tag Manager</Link> och{" "}
          <Link to="/se/data-lager#data-lager-for-adobe-analytics">Adobe Launch</Link>.
        </p>
        <p>
          Därefter kommer vi att titta på implementeringen för de mest populära Tag Management Systems (TMS). Jag kommer att
          förklara <Link to="/se/data-lager#1">design</Link>fasen, följt av{" "}
          <Link to="/se/data-lager#2">implementeringen</Link> och <Link to="/se/data-lager#3">felsökning</Link>.{" "}
        </p>
        <H as="h2">Vad är ett data-lager?</H>
        <p>Ett data-lager är en <b>datastruktur</b> som tillhandahåller relevant information i nyckel-värde-par för användning med till exempel <Link to="/se/tagghantering">Tag Management Systems</Link>.</p>
        <p>Ett data-lager är tillgängligt i det globala JavaScript-området på webbplatsen som en <b>array</b> eller <b>objekt</b> och håller data i en strukturerad form för andra program att använda.</p>
        <p>Fördelen med ett data-lager ligger i ett programmässigt enkelt tillgång till relevant data under ett webbplatsbesök.</p>

        <p>
          Det möjliggör tillgång till data på en central punkt och är grunden för dataanalyslogiken i ett <Link to="/se/tagghantering#vad-ar-ett-tag-management-system">tagghanteringssystem</Link>.
        </p>
        <SnippetJS caption="Kodexempel för att skapa ett data-lager för Google Tag Manager. Det skapar ett JavaScript-objekt i en array (!) som håller alla relevanta dimensioner för senare dataanalys och regeluppbyggnad i tagghanteringssystemet.">
          {`
            window.dataLayer = window.dataLayer || [{
              "pageCategory": "kategorisida",
              "pageName": "sneaker översikt",
              "language": "sv-SE",
            }];`}
        </SnippetJS>
        <p>
          Eftersom det används för att lagra data från flera datakällor, förenklar det övervakningen av aktuella datavärden,
          eftersom endast en enda plats behöver observeras ("single point of truth").
        </p>
        <p>
          Ett data-lager <b>återskapas vid varje sidladdning</b> utrustat med datapunkter på den aktuella webbsidan och
          eventuellt annan relaterad data om besökaren och dess besök.
        </p>
        <p>
          <b>Notera:</b> Ensidiga applikationer (SPA) laddar inte om sidan mellan sidnavigeringen. Det är därför
          konfigurationen av data-lagret för en ensidig applikation är annorlunda än för typiska webbsidor med
          sidladdningar.
        </p>
        <p>
          Den hållna datan representerar egenskaper eller funktioner hos en undersida och hålls i ett nyckel-värde-par. Nycklarna
          håller beskrivande namn på funktionerna parat med ett aktuellt värde, som vanligtvis ändras under användarens resa.
        </p>
        <SnippetJS caption="De relevanta funktionerna på en undersida, såsom dess kategori, namn och språk lagras i variabler och kan senare användas för analys eller regelbyggande i TMS.">
          {`
            window.dataLayer = window.dataLayer || [{
              "pageCategory": "kategorisida", //kategori
              "pageName": "sneaker översikt", //namn
              "language": "se-SE", //språk
            }];`}
        </SnippetJS>
        <p>
          Det övergripande målet är att göra dessa datapunkter tillgängliga i tagghanteringssystemet, så att de kan skickas
          tillsammans med data som delas med t.ex. Google Analytics eller Facebook Ads för att bättre beskriva webbplatsinteraktioner.
        </p>
        <p>
          För att möjliggöra denna integration, håller TMS referenser till nyckel-värde-par och kan till exempel utföra
          regler när deras värde ändras.
        </p>
        <p>
          <b>Exempel:</b> En besökare zoomar in på en produktbild och därmed utlöser en händelse "produktzoom". Utan
          ytterligare data som skickas tillsammans med händelsen är det inte särskilt insiktsfullt. Därför skickar vi också data om
          t.ex. produktnamn, kategori och pris, så att vi kan analysera händelsen i ett meningsfullt sammanhang.
        </p>
        <p>
          Den ytterligare datan skulle göra det möjligt att verifiera om sådana produktzoomar endast förekommer i vissa
          produktkategorier. Om så är fallet, kan det vara fördelaktigt att lägga till fler bilder till andra produkter i samma kategori,
          eftersom besökare verkar vara mycket intresserade av bilddetaljerna för dessa produkter.
        </p>
        <p>
          Slutsatsen är att vi strukturerar all relevant data i beskrivande nycklar och värden och gör dem tillgängliga på en central plats där de enkelt kan hämtas.
        </p>
        <p>
          Sådana datapunkter är vanligtvis viktiga egenskaper hos sidinnehållet eller någon klassificering vi kommit på för att segmentera besökare baserat på beteende.
        </p>
        <p>
          För att bättre förstå vad ett data-lager är, som en förenklad visualisering, kan du tänka dig ett Excel-ark.
          Arket håller viktiga egenskaper om en webbsida i sin rubrik (sökväg, språk, kategori, inloggningsstatus) tillsammans med ett aktuellt värde för varje post.
        </p>
        <ImgContainerFixed width="449px">
          <ImgScreenshot
            src="data-layer/data-layer-structure-excel-example.png"
            alt="Förenklat data-lager exempel i Excel"
            className="article-img"
            caption="Du kan föreställa dig ett data-lager som en enkel tabell med kolumnrubriker"
          />
        </ImgContainerFixed>
        <H as="h2">Vad är fördelarna med att använda ett data-lager?</H>
        <p>
          När en besökare navigerar genom en webbplats sker många användarinteraktioner: klick på knappar, formulär som fylls i eller tittade videor.
        </p>
        <p>
          Om dessa interaktioner tillåter oss att dra slutsatser om användarengagemang, skickas de till t.ex. Google Analytics tillsammans med annan beskrivande data om besökaren, sessionen, själva händelsen eller HTML-elementet de interagerade med.
        </p>
        <p>
          <u>Och detta är den avgörande punkten</u>: Den ytterligare data som beskriver sådana interaktioner kommer från olika
          datakällor, till exempel från <b>frontend, databasen</b> eller en extern <b>API</b>.
        </p>
        <p>
          För att förstå fördelarna med ett data-lager, måste vi först förstå de utmaningar som uppstår när vi behöver data från flera datakällor.
        </p>
        <p>Låt oss titta på ett exempel där vi samlar in data från sådana källor och tänka igenom det:</p>
        <p>
          <b>Exempel:</b> Besökaren köper en produkt på en webbplats. Följande dimensioner kan vara av intresse:
        </p>
        <ul
        >
          <li>produktnamn</li>
          <li>produktpris</li>
          <li>produktstorlek</li>
          <li>produktkategori</li>
          <li>produktfärg</li>
          <li>kundvagnsvärde</li>
          <li>varumärke</li>
          <li>första köp</li>
          <li>kundsegment</li>
          <li>kundrabatt</li>
          <li>kön</li>
          <li>land</li>
        </ul>
        <p>
          Efter köpet landar besökare på en tack-sida som listar alla detaljer om köpet och leveransadressen.
        </p>
        <p>
          <b>Frontend</b>: För att skicka produktdata, kundvagnsvärde och varumärke tillsammans med händelsen, kan vi
          potentiellt skrapa det från tack-sidan.
        </p>
        <p>
          Den största utmaningen med att skrapa data från en webbsida är att datan måste vara tillgänglig på varje sida där
          interaktionen äger rum. Detta är sällan fallet i verkligheten.
        </p>
        <p>
          Det är lämpligt att mäta så många av samma dimensioner över alla interaktioner på en webbplats för att göra
          interaktionerna jämförbara senare under dataanalysen. Därför, om vi skulle följa den metoden, är det troligt
          att andra sidor inte listar <i>produktdata, kundvagnsvärde och varumärke</i> att skicka tillsammans med
          andra händelser.
        </p>
        <p>
          Så om den nödvändiga datan inte är tillgänglig på andra sidor, vill vi undvika att lägga till all den datan till innehållet
          bara för analysens skull. <b>Därför använder vi ett data-lager</b>. Det gör datan tillgänglig för oss att samla in,
          oavsett om den är synlig på sidan eller inte. Det är bokstavligen ett lager av data som ligger ovanpå varje given
          undersida och tillhandahåller den data vi behöver.
        </p>
        <p>
          En annan utmaning med att skrapa data från frontend är att det till slut bryter. När sidor ändras och dessa ändringar
          påverkar HTML-strukturen på de skrapade elementen, då kommer datainsamlingen att bryta. Speciellt i större företag
          sker frekventa ändringar på sidor och flera team arbetar på dem utan att veta om vissa HTML-element behövs för datainsamling.
          Därför kommer all skrapning av data från frontend att bryta vid någon tidpunkt på ofta uppdaterade webbplatser.
        </p>
        <p>
          Ett data-lager utnyttjar detta tillvägagångssätt och gör det möjligt att hämta användardata på ett säkert och ändå enkelt
          sätt.
        </p>
        <p>
          <b>Databas</b>: Att samla in kunddata (kundsegment, rabatt, kön och första köp) kan bli lite krångligt: Kunddata skulle
          antingen behöva skickas tillsammans med serverresponsen eller med ett separat API.
        </p>
        <p>
          Men eftersom detta är privata data, måste insamlingen vara autentiserad för dataskyddssyften. Det innebär att en API-begäran
          inte kunde hanteras i webbläsaren eftersom API-nyckeln annars skulle vara tillgänglig för erfarna användare.
        </p>
        <p>
          Därför är den bästa lösningen att skicka datan tillsammans med serverresponsen baserat på webbplatsens inloggningsautentisering. <br />
          När användaren är inloggad, fylls data-lagret med relevant data från databasen. Utan inloggning exponeras ingen känslig data.
        </p>
        <p>
          <b>API</b>: Geodata som landet kan hämtas från en extern tjänst-API.
        </p>
        <p>
          Dock uppstår samma utmaning som vid hämtning av data från databasen: Alla API-begäranden från frontend eller tagghanteringssystemet
          kräver en API-nyckel, som inte bör hanteras i webbläsaren av säkerhetsskäl.
        </p>
        <p>
          En annan nackdel med att arbeta med API:er för datainsamling, särskilt med händelser, är tiden tills datan kommer tillbaka.
          Om en användare navigerar till en annan sida innan datan har anlänt, riskerar vi att förlora händelsen.
        </p>
        <p>Låt oss sammanfatta fördelarna snabbt:</p>
        <H as="h3">Fördelar</H>
        <ul>
          <li>Data är tillgänglig oavsett om den är synlig på sidan eller inte</li>
          <li>Robust datainsamling</li>
          <li>Säker insamling av känslig data</li>
          <li>Förhindrar dataförlust vid asynkrona databehov</li>
        </ul>
        <H as="h2">Varför du förmodligen behöver en</H>
        <p>
          Genom att skapa ett data-lager görs ett JavaScript-objekt tillgängligt i det globala området i webbläsaren vid varje sidladdning.
        </p>
        <p>
          Datan det innehåller kan härledas från din databas, frontend eller API:er, så datainsamling från dessa källor blir pålitlig, säker och oberoende av HTML på sidan.
        </p>
        <p>
          Data från databasen kan göras tillgänglig på vilken undersida som helst av webbplatsen utan större besvär utan att vara synlig i innehållet.
        </p>
        <p>
          Av ovanstående skäl råder jag generellt kunder att implementera data-lager om de är seriösa med sina dataanalysambitioner. Fördelarna med datakvalitet, pålitlighet och de relaterade långsiktiga tidsbesparingarna rättfärdigar de högre implementeringsinsatserna.
        </p>
        <p>
          Det ultimata målet med webbanalys är att fatta datadrivna affärsbeslut, så datakvalitet bör vara en prioritet.
        </p>
        <p>
          Låt oss nu titta på de olika alternativen som finns och några implementeringsexempel innan vi dyker in i design- och implementeringsfasen.
        </p>
        <H as="h2">Data-lager för Adobe Analytics, DTM, Launch och Tealium</H>
        <p>
          Data-lager kan ha olika strukturer. Generellt skiljer vi mellan de som kommer med en <b>objektbaserad struktur</b> och en <b>array-baserad struktur</b>.
        </p>
        <p>
          Enligt{" "}
          <a href="https://www.w3.org/2013/12/ceddl-201312.pdf" rel="noopener" target="_blank">
            definitionen av data-lagret
          </a>{" "}
          från World Wide Web Consortium (W3C) följer syntaxen den för ett JavaScript-objekt. Det förkortas inofficiellt <b>CEDDL</b> (Customer Experience Digital Data Layer).
        </p>
        <p>Du kan också nästla andra objekt eller arrayer i det:</p>
        <SnippetJS caption="Exempel på ett data-lager för Adobe Analytics med en objektbaserad struktur, enligt W3C-definitionen.">
          {`
            window.digitalData = {
              pageName: "sneaker översikt",
              destinationPath: "/se/sneakers",
              breadCrumbs: ["hem","sneakers"],
              publishDate: "2020-07-01",
              language: "se-SE"
          };`}
        </SnippetJS>
        <p>
          <b>Adobe Analytics, Adobe Launch</b> och Tealium följer CEDDL-strukturen. I exemplet ovan lagrar vi data i ett objekt som heter <code>digitalData</code>. Namnet är inte standardiserat och kan väljas fritt, men du måste deklarera namnet i tagghanteringssystemet.
        </p>
        <p>
          För att ändra datan finns det flera alternativ (som med vilket JS-objekt som helst), men det enklaste sättet är att bara skriva över värdena:
        </p>
        <SnippetJS caption="För att lägga till data i data-lagret, skriv bara över befintlig data som med vilket vanligt JavaScript-objekt som helst.">
          {`window.digitalData.language = "de-DE";`}
        </SnippetJS>
        <p>
          Den centrala idén med den objektbaserade strukturen är att de laddas en gång per sidladdning, men de ändras inte
          mycket baserat på användarinteraktion. Datan är mestadels <b>statisk</b>.
        </p>
        <p>
          Händelsespårning drivs inte av händelser som kommer in i data-lager-objektet. Händelser spåras med ett{" "}
          <b>separat spårningsbibliotek</b> för att skicka dem vidare till en analysplattform t.ex. Adobe Analytics. När
          händelsespårningskoden körs skickas data-lager-objektet med i sin helhet och kan användas under dataanalysen.
        </p>
        <SnippetJS caption="Händelsespårning för Adobe med en objektbaserad data-lager-struktur hanteras genom _satellite-biblioteket.">
          {`
            //Händelse med vald färg
            _satellite.setVar("sneaker color", "black");
            _satellite.track("select color"); 
          `}
        </SnippetJS>
        <H as="h2">Använd Adobe Launch med ett array-baserat data-lager</H>
        <p>
          Du kan enkelt använda Adobe Launch med en array-baserad struktur också. Adobe Launch Extension{" "}
          <b>Data Layer Manager</b> gör det möjligt.
        </p>
        <p>Här är några länkar till ytterligare resurser för att använda den array-baserade versionen med Adobe Launch:</p>
        <ul>
          <li>
            Jim Gordons{" "}
            <a
              href="https://jimalytics.com/data-layers/event-driven-data-layer-eddl-demo/"
              rel="noopener"
              target="_blank"
            >
              Demo av att använda Data Layer Manager med Adobe Launch
            </a>
          </li>

          <li>
            <a
              href="https://exchange.adobe.com/experiencecloud.details.101462.data-layer-manager.html"
              rel="noopener"
              target="_blank"
            >
              Data Layer Manager
            </a>{" "}
            Extension med{" "}
            <a
              href="https://techdocs.searchdiscovery.com/adobe-solutions/adobe-launch/launch-extensions/data-layer-manager"
              rel="noopener"
              target="_blank"
            >
              dokumentation
            </a>
          </li>
        </ul>
        <H as="h2">Data-lager för Google Tag Manager, Matomo och Piwik Pro</H>
        <p>
          <b>Data-lagret för Google Tag Manager</b>, Matomo och Piwik Pro är array-baserat och inofficiellt refererat till som
          det händelsedrivna data-lagret <b>(EDDL)</b>.
        </p>
        <p>
          Data hanteras också i objekt, men den övergripande strukturen för GTM-data-lagret är en <b>array med objekt</b>.
        </p>
        <SnippetJS caption="Kodexempel för att lägga till ett GTM-data-lager">
          {`
            window.dataLayer = window.dataLayer || [{
              "pageCategory": "kategorisida",
              "pageName": "sneaker översikt",
              "language": "sv-SE",
            }];
            `}
        </SnippetJS>
        <p>
          Spårningslogiken med en array-baserad struktur är annorlunda: Ny data eller ändringar skjuts in i den via{" "}
          <code>dataLayer.push()</code>. Så en <b>push till data-lagret</b> kan sedan utlösa taggexekveringar i tagghanteringssystemet.
        </p>
        <p>
          Den grundläggande skillnaden mot en objektbaserad struktur är att ändringar vanligtvis skickas tillsammans med en
          händelse och att regler utlöses baserat på dessa ändringar utan något ytterligare bibliotek, bara genom att observera
          om data-lager-arrayen ändras.
          <br />
          Eftersom inget annat bibliotek som <code>_satellite</code> är nödvändigt, behöver vi en beroende mindre.
        </p>
        <p>
          En annan egenskap hos det array-baserade tillvägagångssättet är att datan ändras ganska ofta under
          användarens resa eftersom alla användarinteraktioner kan ändra data-lagervariablerna.
        </p>{" "}
        <p>
          Så ett array-baserat data-lager är grunden för händelsespårning och hanterar data mer flexibelt medan ett
          objektbaserat snarare fungerar som ett statiskt datalager.
        </p>
        <p>
          Genom den flexibiliteten anses en array-baserad data-lager-struktur vara mer lämplig för
          Single-Page-Applications. <br />
          Du kan dock även spåra SPA:er med ett objektbaserat data-lager, det kommer bara att kräva några fler rader kod och potentiellt några edge cases att lösa.
        </p>
        <p>
          Om du är i början av ett projekt och har valet, skulle jag föredra ett array-baserat data-lager
          dock.
          <br />
          Att ändra en redan befintlig uppsättning från en objektstruktur till en array är dock onödigt.
        </p>
        <H as="h2">Content Management Systems med data-lager inkluderat</H>
        <p>
          <b>WordPress</b> användare har det enkelt eftersom de kan använda{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">
            detta plugin
          </a>{" "}
          för att implementera Google Tag Manager tillsammans med ett förkonfigurerat data-lager.
        </p>
        <p>Det fylls automatiskt med kategorier, författarnamn, publiceringsdatum och söktermer.</p>
        <p>
          Datapunkterna kan kontrolleras eller avmarkeras i plugin-inställningarna. Dessutom erbjuder pluginet
          förkonfigurerade händelser för formulärinlämningar av de vanligaste formulärpluginsen.
        </p>
        <p>
          Om du är en webshop-ägare och använder <b>WooCommerce</b> för WordPress, kan du implementera ett{" "}
          <b>klassiskt e-handels data-lager</b> såväl som ett <b>förbättrat e-handels data-lager</b> med samma
          plugin, vilket är ganska kraftfullt.
        </p>
        <p>
          <b>WordPress</b> användare som vill använda <b>Tealium</b> kan använda ett plugin för{" "}
          <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/tealium/">
            Tealium
          </a>
          .
        </p>
        <p>
          <b>Drupal</b> har också ett{" "}
          <a rel="noopener" target="_blank" href="https://www.drupal.org/project/dataLayer">
            plugin
          </a>
          .
        </p>
        <p>
          <b>Wix</b> och <b>Squarespace</b> användare kan implementera Google Tag Manager genom plattformverktygen men måste
          implementera data-lagerkoden manuellt.
        </p>
        <H as="h2">Data-lager implementering</H>
        <p>
          Så hur implementerar man ett data-lager? - Eftersom planering och implementering kräver kunskap inom områdena
          digital analys, frontend-utveckling och backend-utveckling, utförs implementeringen vanligtvis genom en webbyrå tillsammans med en{" "}
          <Link to="/se/google-analytics-konsult">analyskonsult</Link>.
        </p>
        <p>
          Analyskonsulten instruerar webbyrån och leder projektet tills implementeringen är
          framgångsrikt validerad. Därefter konfigureras tagghanteringssystemet och analysverktygen.
        </p>
        <p>
          Om du är intresserad och kan lite JavaScript kan du implementera det själv med följande
          <b>implementeringsguide</b>.
        </p>
        <p>Implementeringen går igenom 3 steg:</p>
        <ol>
          <li>
            <Link to="/se/data-lager#1">Data Layer Design</Link>
          </li>

          <li>
            <Link to="/se/data-lager#2">Implementering</Link>
          </li>

          <li>
            <Link to="/se/data-lager#3">Felsökning</Link>
          </li>
        </ol>
        <H as="h3">1. Data Layer Design</H>
        <p>
          Designfasen handlar om att definiera vilka interaktioner som ska mätas tillsammans med vilka dimensioner.
        </p>
        <p>
          Alla attribut för besökaren, sessionen, sidan, produkten eller händelsen kan vara av potentiellt intresse under dataanalysen och bör beaktas för data-lager-arkitekturen.
        </p>
        <p>
          För att bestämma vad som ska inkluderas, börja med slutet i åtanke och fråga dig själv vilka affärskritiska frågor som behöver besvaras och fokusera på de relaterade datapunkterna.
        </p>
        <p>
          Nästa steg är att ta reda på hur dessa datapunkter måste struktureras och vilka dimensioner som är viktigast att lägga till.
        </p>
        <p>
          <b>Exempel</b>: En språkskola som driver en WordPress-webbplats på flera språk vill veta modersmålet för sina webbplatsbesökare och vilket främmande språk de är mest intresserade av. Målet är att eventuellt köra onlineannonsering via Facebook Ads som riktar sig till demografiska grupper med liknande attribut.
        </p>
        <p>
          Som nästa steg skulle vi behöva definiera all relevant data över olika typer av sidor (hemsida, kurssidor, om oss, kontakt och nyheter). För att förenkla, låt oss titta på de mest intressanta sidorna och fokusera på startsidan och kurssidorna.
        </p>
        <ImgContainerFixed width="400px">
          <ImgScreenshot
            src="data-layer/data-layer-example-language-school.png"
            alt="Data-lager design exempel för en språkskolas webbplats"
            caption="Exempel på en data-lager design för en språkskolas startsida och kurssidor"
          />
        </ImgContainerFixed>
        <p>
          <b>Exempel</b>: Array-baserat Google Tag Manager data-lager för en språkskola
        </p>
        <SnippetJS caption="Kodexempel för att initiera ett GTM data-lager för ett språkskolaexempel. Observera att siffror deklareras som strängar.">
          {`
            window.dataLayer = window.dataLayer || [{
              "language": "de", //Språk för UI
              "sessionDuration": "182", //Sessionslängd i sek
              "languageIntent": "es", //mest besökta kursspråk
              "pageType": "kurssida",
              "courseName": "Spanska A1 - Nybörjare",
              "courseLang": "es",
              "courseLevel": "a1",
              "courseDuration": "6" //Längd i veckor
            }];
            `}
        </SnippetJS>
        <p>
          <b>Exempel</b>: Objektbaserat data-lager för Adobe Launch
        </p>
        <SnippetJS caption="Initialisering av ett data-lager för Adobe Launch eller Adobe DTM.">
          {`
            window.digitalData = window.digitalData || {
              "language": "de", //Språk för UI
              "sessionDuration": 182, //Sessionslängd i sek
              "languageIntent": "es", //mest besökta kursspråk
              "pageType": "kurssida",
              "courseName": "Spanska A1 - Nybörjare",
              "courseLang": "es",
              "courseLevel": "a1",
              "courseDuration": 6 //Längd i veckor
            };
          `}
        </SnippetJS>

        <H as="h3">2. Implementering</H>
        <p>
          Data-lager måste implementeras på varje undersida av en webbplats. Kodeexemplen ovan visar dock det slutliga <b>beräknade tillståndet</b>.
        </p>
        <p>
          Under implementeringen måste datapunkterna först hämtas för att beräknas till sitt slutliga tillstånd, så den faktiska källan kommer att se något annorlunda ut.
        </p>
        <p>För att ge ett realistiskt exempel antar jag följande:</p>
        <ul>
          <li>
            Sessionslängd och språkintresse samlas in via en egenutvecklad JavaScript och hålls i webbläsarens lokala lagring.
          </li>
          <li>
            Språk, sidtyp och kursdata kan hämtas från databasen via serverresponsen och göras tillgängliga på kurssidorna och startsidan.
          </li>
        </ul>
        <p>Källkoden för data-lagret i backend enligt ovanstående premisser skulle se ut så här:</p>
        <SnippetJS caption="Exempel på källkod för ett data-lager före beräkning.">
          {`
            window.dataLayer = window.dataLayer || [{
              "language": <?php echo wpb_getpagedata("lang"); ?>,
              "sessionDuration": window.localStorage.sessionDuration,
              "languageIntent": window.localStorage.languageIntent
              "pageType": <?php echo wpb_getpagedata("type"); ?>,
              "courseName": <?php echo wpb_getcoursedata("name"); ?>,
              "courseSprache": <?php echo wpb_getcoursedata("lang"); ?>,
              "courseLevel": <?php echo wpb_getcoursedata("level"); ?>,
              "courseDuration": <?php echo wpb_getcoursedata("duration"); ?>,
            }];
          `}
        </SnippetJS>
        <H as="h4">Händelsespårning med data layer push</H>
        <p>För att skicka händelser till ett GTM data-lager kan du använda dess push-metod och bokstavligen trycka in händelser i det.</p>
        <SnippetJS caption="Data layer push-exempel för händelsespårning">
          {`
            window.dataLayer.push({
              "event": "course-booking", 
              "startWeek": "24"
            });
          `}
        </SnippetJS>
        <p>
          Nyckelordet <b>event</b> är ett speciellt nyckelord och kan adresseras som en <b>custom event</b> från GTM-behållaren.
        </p>
        <p>
          Tagghanteringssystemet observerar data-lagret och kör en tagg så snart en fördefinierad custom event skickas till det.
        </p>
        <p>Efter att en händelse har lagts till kan TMS till exempel skicka en händelse till Google Analytics.</p>
        <p>
          All relevant data för att ge kontext (namn, språk, språknivå, kurslängd) är tillgänglig och kan skickas tillsammans med händelsen, till exempel startveckan för kursen.
        </p>
        <p>
          I ett objektbaserat data-lager skulle samma händelse skickas direkt till Adobe Analytics via deras eget händelsespårningsbibliotek.
        </p>
        <p>För Adobe Launch skulle exempel koden se ut så här:</p>
        <SnippetJS caption="Adobe Launch-exempel för händelsespårning. Observera att datan skickas direkt till Adobe Analytics utan att gå igenom data-lagret först">
          {`
            _satellite.setVar("startWeek", 24);
            _satellite.track("course-booking");
          `}
        </SnippetJS>
        <H as="h4">Kodpositionering i källkoden</H>
        <p>
          Data-lagerkoden bör läggas till i <code>{`<head>`}</code> på sidan före tagghanteringssystemet.
        </p>
        <p>
          På grund av denna ordning säkerställer du att den redan är beräknad när tagghanteringssystemet vill komma åt den.
        </p>
        <p>
          <b>Exempel</b>: Positionering i källkoden
        </p>
        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="positionering av data-lagret före TMS-taggen i källkoden"
          caption="Data-lagret bör placeras i <head>-sektionen före tagghanteringssystemet, för att säkerställa att dess data redan är laddad när TMS vill komma åt den."
        />
        <H as="h3">3. Felsökning</H>
        <p>
          De vanligaste procedurerna för att felsöka ett data-lager är att simulera sidladdningar eller händelser för att verifiera att alla datapunkter fylls med korrekt data.
        </p>
        <p>
          Eftersom det är globalt tillgängligt i webbläsarkonsolen kan du enkelt skriva ut alla värden till konsolen
          (förutsatt att standardnamngivningskonventioner tillämpas):
        </p>
        <p>
          <b>Webbplatser med GTM</b>
        </p>
        <p>
          <code>Object.assign(...dataLayer)</code>
        </p>
        <p>
          <b>Webbplatser med Adobe Launch eller DTM</b>
        </p>
        <p>
          <code>digitalData</code>
        </p>
        <p>
          <b>Tealium</b>
        </p>
        <p>
          <code>utag.data</code> eller <code>utag_data</code>
        </p>
        <p>
          Google Tag Manager har till och med sitt eget <strong>Debugger Mode</strong>. Du kan aktivera det från GTM
          gränssnittet genom att klicka på <b>förhandsgranska</b> uppe till höger.
        </p>
        <ImgScreenshot
          src="data-layer/gtm-activate-debugger-mode.png"
          alt="Skärmbild av Google Tag Manager som visar knappen för att aktivera felsökningsläget"
          caption={`Du kan aktivera GTM:s felsökningsläge genom att klicka på förhandsgranskningsknappen. Om du sedan besöker din webbplats med den installerade GTM-behållaren, kommer felsökningsfönstret att dyka upp längst ner i webbläsaren.`}
        />
        <ImgScreenshot
          src="data-layer/gtm-debugger-mode.png"
          alt="Skärmbild av GTM felsökningsfönstret"
          caption="Du kan inspektera alla data-lagervariabler och se deras aktuella värden medan du interagerar med webbplatsen. Dessutom kan du överblicka sekvensen av händelser som kommer in. Klicka på varje händelse för att se vilka ändringar händelsen orsakade."
        />
        <p>
          Om du inte har tillgång till tagghanteringsbehållaren men ändå vill felsöka den, kan du använda en{" "}
          <b>chrome extension</b>.
        </p>
        <H as="h4">Data Layer Chrome Extensions</H>
        <p>
          Det finns en mängd potentiella tillägg för felsökning där ute. Jag föredrar de som stöder de flesta
          leverantörer, så jag inte behöver byta mellan tillägg när jag felsöker en annan webbplats.
        </p>
        <p>Följande chrome extensions är för närvarande mina favoriter för felsökning:</p>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh"
            >
              <b>Trackie</b>
            </a>{" "}
            - Tillägg baserat på{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon"
            >
              Data Slayer
            </a>{" "}
            och open source. Det har ganska bra prestanda och stöder GTM, DTM, Tealium{" "}
            <a rel="noopener" target="_blank" href="https://github.com/pualien/Trackie#trackie">
              och många fler
            </a>
            .
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl"
            >
              <b>Omnibug</b>
            </a>{" "}
            - En annan all-rounder med stöd för Adobe Analytics (DTM & Launch), samt Matomo, GTM, Tealium{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://github.com/MisterPhilip/omnibug/tree/master/src/providers"
            >
              och fler
            </a>
            .
          </li>
        </ul>
        <H as="h4">Chrome Extensions för felsökning av Google Analytics och GTM</H>
        <ul>
          <li>
            <a href="https://chrome.google.com/webstore/detail/gtmga-debug/ilnpmccnfdjdjjikgkefkcegefikecdc?utm_source=chrome-ntp-icon">
              <b>GTM/GA Debug</b>
            </a>{" "}
            - efter att ha aktiverat tillägget kommer det att finnas en ny flik tillgänglig i Chrome DevTools när du öppnar dem
            (F12 på Windows och CTRL+SHIFT+i på Mac). All relevant data visas i ett grafiskt gränssnitt och uppdateras
            medan du surfar på en webbplats.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/adswerve-datalayer-inspec/kmcbdogdandhihllalknlcjfpdjcleom?utm_source=chrome-ntp-icon">
              <b>Adswerve - dataLayer Inspector+</b>
            </a>{" "}
            - loggar alla relevanta datapunkter i webbläsarkonsolen. Aktivera "preserve log" i konsolinställningarna
            för att behålla loggar över sidnavigering.
          </li>
          <li>
            <a href="https://chrome.google.com/webstore/detail/dataslayer/ikbablmmjldhamhcldjjigniffkkjgpo?utm_source=chrome-ntp-icon">
              <b>Data Slayer</b>
            </a>{" "}
            - definitivt tillägget med det coolaste namnet och logotypen och min tidigare favorit. Det är open source
            med en enkel layout och fungerar även med Adobe DTM. <br />
            <u>
              Avmarkera “use three-column layout where available”, “show GA Classic tags” och “show Floodlight tags” i
              tilläggsinställningarna,
            </u>{" "}
            annars blir loggarna lite röriga.
          </li>
        </ul>
        <H as="h4">Chrome Extensions för felsökning av Adobe Analytics, Launch och DTM</H>
        <ul>
          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/launch-and-dtm-switch/nlgdemkdapolikbjimjajpmonpbpmipk"
            >
              <b>Launch & DTM Switch</b>
            </a>{" "}
            - låter dig ladda staging- eller produktionsbiblioteket för tagghanteringssystemet och kan aktivera
            felsökningsläget.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/debugger-for-adobe-analyt/bdingoflfadhnjohjaplginnpjeclmof"
            >
              <b>Debugger for Adobe Analytics</b>
            </a>{" "}
            - aktiverar felsökningsläget. Alternativt kan du också skriva <code>_satellite.setDebug(true)</code> i
            konsolen.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-cloud-de/ocdmogmohccmeicdhlhhgepeaijenapj"
            >
              <b>Adobe Experience Cloud Debugger</b>
            </a>{" "}
            - meta-extension för att felsöka alla Adobe-produkter.
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/adobe-experience-platform/bfnnokhpnncpkdmbokanobigaccjkpob"
            >
              <b>Adobe Experience Platform Debugger</b>
            </a>{" "}
            - efterföljaren till experience cloud debugger som erbjuder en bättre översikt (för närvarande fortfarande i beta).{" "}
          </li>

          <li>
            <a
              rel="noopener"
              target="_blank"
              href="https://chrome.google.com/webstore/detail/tealium-data-layer-debugg/aegehiegfbndemonfanncbgdfafpfabm/"
            >
              <b>Tealium Data Layer Debugger</b>
            </a>{" "}
            - enkla tabeller över alla aktuella värden.
          </li>
        </ul>
        <H as="h2">E-handels Data Layer</H>
        <p>
          Data-lager för e-handel är mer omfattande och deras struktur är mer komplex. De måste hålla mer
          data och hantera fler händelser.
        </p>
        <p>Därför tar planering och implementering av en e-handelswebbplats betydligt mer tid.</p>
        <p>
          E-handelsrapporten i Google Analytics, till exempel, visar inga data om implementeringen inte följer deras{" "}
          <a rel="noopener" target="_blank" href="https://support.google.com/tagmanager/answer/6107169?hl=de">
            dokumentation för e-handels data layer
          </a>
          .
        </p>
        <p>
          Stora e-handelsbutiker kräver dock ännu mer avancerade spårningsinställningar. De implementerar ett{" "}
          <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/enhanced-ecommerce">
            data layer för förbättrad e-handel
          </a>
          , vilket möjliggör ännu mer funktionalitet i Google Analytics.
        </p>
        <p>
          Du måste strikt följa implementeringsriktlinjerna för Google Analytics för att e-handelsrapporterna ska fungera. Det gäller data layer-strukturen och även variabelnamn.
        </p>
        <p>
          Om du väljer en annan analysplattform för e-handel, är du fri att planera strukturen som du vill.
        </p>
        <H as="h2">När är ett data layer inte nödvändigt?</H>
        <p>
          Som med allt, finns det också fall där den extra ansträngningen att implementera ett data layer inte är motiverad.
        </p>
        <p>
          I exemplen ovan tittade vi på fall där vi hämtade data från olika datakällor (Frontend, Backend, API) och löste problem som uppstod när vi arbetade med en mängd olika datakällor.
        </p>
        <p>
          Många webbplatser (så kallade broschyr-webbplatser) har dock inte ens en inloggningsfunktion eller en databas.
        </p>
        <p>
          En annan viktig faktor är hur ofta ändringar implementeras på webbplatsen. Många webbplatser granskar sällan sitt innehåll eller lägger till funktionalitet regelbundet. Jag ser till och med företag som driver enkla broschyr-webbplatser med cirka 50 undersidor och ett kontaktformulär som den svåraste konverteringen.
        </p>
        <p>
          Eftersom sådana webbplatser troligen bara kräver data från frontend för att göra sin dataanalys, kan de klara sig med en enkel analysuppsättning utan något data layer. Det skulle inte göra insamlingsdelen mycket mer robust eller säkrare, varför dess fördelar minskar. Under sådana omständigheter rättfärdigar fördelarna inte den extra implementeringsinsatsen.
        </p>
        <p>
          Typiska exempel när ett data layer inte är nödvändigt är broschyrwebbplatser eller innehållswebbplatser med ett begränsat antal eller nästan inga svåra konverteringar. Vanligtvis är sådana webbplatsägare bara intresserade av att kategorisera användarengagemanget efter deras innehållssektioner eller några företagsinterna klassificeringar.
        </p>
        <p>
          Sådana krav kan uppnås med avancerad JavaScript och ett genomtänkt system för att strukturera innehållet.
        </p>
        <p>
          Så snart datainsamlingen från frontend regelbundet bryter och definitivt när en databas bör vara inblandad, rekommenderas ett data layer.
        </p>
        <p>
          Alternativa lösningar är ofta bara tillfälligt tillfredsställande, på grund av ständigt ökande analysambitioner och regelbundet brytande datainsamling. Dessutom är alla anpassade lösningar vanligtvis svåra att överlämna till en annan byrå.
        </p>
        <p>
          Ett data layer har goda chanser att överleva tidens tand eftersom det redan är ett etablerat koncept inom webbanalyssektorn, så webbyråer har ökad erfarenhet av att implementera och underhålla ett.
        </p>
        <H as="h2">Slutsats</H>
        <p>
          Ett data-lager är guldstandarden för professionella analysuppsättningar. Det ökar datakvaliteten och förbättrar därmed dataanalysen som helhet, samtidigt som säkerhetskraven uppfylls.
        </p>
        <p>
          För ambitiösa webbplatsägare som vill börja med seriös dataanalys är det den enklaste och mest robusta lösningen.
        </p>
        <p>
          Om du har valet, implementera en array-baserad struktur, eftersom du har färre beroenden och kan använda den på alla typer av webbplatser.
        </p>
        <p>
          Innehållswebbplatser är dock så begränsade i funktionalitet och ger vanligtvis bara ett begränsat antal svåra konverteringar, så ett data-lager kan potentiellt försummas. Detta gäller särskilt om all nödvändig data finns tillgänglig på sidorna eller kan göras tillgänglig genom några omvägar.
        </p>
        <p>
          Om du vill implementera en själv är det förmodligen enklast att göra det med en WordPress-webbplats. Alla avancerade krav är dock förmodligen inte värda att spendera tiden på och riskera ett otillfredsställande resultat.
          <br />
          Därför är det vanligtvis bäst att implementera med hjälp av en analytisk konsult, eftersom det sparar tid och undviker onödiga risker.
        </p>
        <p>
          Jag rekommenderar att du installerar en av de nämnda Chrome-tilläggen för att inspektera data-lager på några större webbplatser där ute. Det är vanligtvis en bra inspiration och ger några intressanta KPI:er att potentiellt integrera i dina egna analysuppsättningar 😉.
        </p>
        <H as="h2">Dokumentation av Data Layer från olika TMS-leverantörer</H>
        <ul>
          <li>
            <b>Google Tag Manager</b>:{" "}
            <a rel="noopener" target="_blank" href="https://developers.google.com/tag-manager/devguide">
              Initiering och ändring av data
            </a>
          </li>

          <li>
            <b>Adobe Launch</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.adobe.com/content/help/en/analytics-learn/tutorials/implementation/via-adobe-launch/using-a-data-layer-to-set-page-name-and-other-variables-via-launch.html"
            >
              Initiering
            </a>
          </li>

          <li>
            <b>Tealium iQ</b>:{" "}
            <a rel="noopener" target="_blank" href="https://docs.tealium.com/platforms/javascript/data-layer-object/">
              Initiering
            </a>{" "}
            och{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://docs.tealium.com/platforms/javascript/single-page-applications/#examples-1"
            >
              ändring av data
            </a>
          </li>

          <li>
            <b>Matomo</b>:{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/embedding#finding-the-embed-code"
            >
              Initiering
            </a>{" "}
            och{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://developer.matomo.org/guides/tagmanager/datalayer#setting-a-variable"
            >
              ändring av data
            </a>
          </li>

          <li>
            <b>Piwik Pro</b>:{" "}
            <a rel="noopener" target="_blank" href="https://help.piwik.pro/support/tag-manager/create-a-data-layer">
              Initiering
            </a>{" "}
            och{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://help.piwik.pro/support/tag-manager/generating-new-event-fire-tags/"
            >
              ändring av data
            </a>
          </li>
        </ul>
        <H as="h2">FAQ</H>
        <FaqComponent data={{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Vad är ett exempel på ett data-lager?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Ett exempel på ett data-lager finns i artikeln. Ett JavaScript-objekt lagrar data från en webbplats, databas eller en extern källa på ett centralt, flexibelt och lättillgängligt sätt. Ett exempel på kodsnutt för att initiera ett data-lager för Google Tag Manager är: window.dataLayer = window.dataLayer || [{ \"pageCategory\": \"category page\", \"pageName\": \"sneaker overview\", \"language\": \"en-US\",}];"
              }
            },
            {
              "@type": "Question",
              "name": "Vad är data-lagervariabler?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Data-lagervariabler är nyckel-värde-par inom data-lagret som lagrar specifika informationsbitar. Dessa variabler kan inkludera sidkarakteristika, användarbeteendedata och mer, och fungerar som ett centralt datalager för analys och spårning."
              }
            },
            {
              "@type": "Question",
              "name": "Varför använda ett data-lager?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Ett data-lager är viktigt för robust, flexibel och säker datainsamling. Det centraliserar data från olika källor, vilket gör det lättillgängligt och konsekvent över olika webbsidor och användarinteraktioner. Detta tillvägagångssätt förbättrar datakvaliteten och tillförlitligheten, vilket är avgörande för datadrivna beslut."
              }
            },
            {
              "@type": "Question",
              "name": "Behöver jag ett data-lager?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Även om det inte alltid är nödvändigt, rekommenderas generellt ett data-lager för de som är seriösa med sina dataanalysambitioner. Det ger datakvalitet, tillförlitlighet och långsiktiga tidsbesparingar som motiverar de högre implementeringsinsatserna."
              }
            },
            {
              "@type": "Question",
              "name": "Vilka är fördelarna med ett data-lager?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Fördelarna med ett data-lager inkluderar: Tillgänglighet av data oavsett om den är synlig på sidan. Robust datainsamling. Förhindrande av dataförlust vid asynkrona databehov. Säker datainsamling från flera källor."
              }
            },
            {
              "@type": "Question",
              "name": "Har alla webbplatser ett data-lager?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Inte alla webbplatser har ett data-lager. Implementeringen beror på webbplatsens komplexitet och djupet av den dataanalys som krävs. Enkla webbplatser kanske inte har ett data-lager, medan mer komplexa webbplatser, särskilt de som fokuserar på datadrivna beslut, troligtvis kommer att ha ett."
              }
            },
            {
              "@type": "Question",
              "name": "Hur kommer jag åt data-lagret?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Data-lagret är globalt tillgängligt i webbläsarkonsolen. För webbplatser med Google Tag Manager kan du komma åt det med hjälp av dataLayer eller Object.assign(...dataLayer). För Adobe Launch eller DTM kan du komma åt det med hjälp av digitalData."
              }
            },
            {
              "@type": "Question",
              "name": "Hur pushar du till data-lagret?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "För att pusha till data-lagret använder du metoden dataLayer.push(). Till exempel: window.dataLayer.push({ \"event\": \"course-booking\", \"startWeek\": \"24\" }); Denna metod används för att lägga till ny data eller ändringar till data-lagret. Event-nyckeln kan användas för att utlösa en annan taggexekvering i tagghanteringssystemet."
              }
            }
          ]
        }
        } />
        <H as="h2">Ytterligare Resurser</H>
        <ul>
          <li>
            Simo Ahava om{" "}
            <a rel="noopener" target="_blank" href="https://www.simoahava.com/analytics/data-layer/">
              data-lagret i GTM
            </a>{" "}
            och{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.simoahava.com/analytics/javascript-101-gtm-part-1/#4-interacting-with-datalayer"
            >
              hur man hanterar data i det.
            </a>
          </li>
          <li>Läs min <Link to="/se/google-tag-manager-setup">Google Tag Manager-tutorial</Link> och lär dig hur man sätter upp det.</li>
          <li>
            Kevin Haags presentation från Measurecamp Berlin 2019 om{" "}
            <a
              rel="noopener"
              target="_blank"
              href="https://www.slideshare.net/KevinHaag5/about-the-eventdriven-data-layer-adobe-analytics"
            >
              Event Driven Data Layer i Adobe Analytics
            </a>
          </li>
        </ul>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default DataLayer;